import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMedia } from '../../api/app/media'

/** Hooks that receive the current media item (MUST BE A VIDEO PRODUCT)
 *  and compute if rotation/trimmer has changed, using redux state as comparaison base */
const useVideoState = (item) => {
	const dispatch = useDispatch()

	const currentRotation = useSelector((s) => s.videoEditing.rotation.current)
	const trimmer = useSelector((s) => s.videoEditing.trimmer)

	// Handle has Changes
	const [hasChanged, setHasChanged] = useState(false)

	/**
	 * Define if the data has changed
	 * */
	useEffect(() => {
		if (trimmer?.timeRange?.start != item?.startTime) {
			setHasChanged(true)
		} else if (trimmer?.timeRange?.end != item?.endTime) {
			setHasChanged(true)
		} else if (currentRotation != item?.rotation) {
			setHasChanged(true)
		} else {
			setHasChanged(false)
		}
	}, [trimmer, currentRotation, item])

	const handleSave = useCallback(() => {
		// Do whatever with the trimmer information from the store ...
		// You might also want to pass in the rotation picked by the user...
		let updateData = {
			id: item.id,
		}
		if (currentRotation) updateData.rotation = currentRotation // don't set to null if this value is not present.
		if (trimmer.timeRange.start >= 0) updateData.startTime = trimmer.timeRange.start
		if (trimmer.timeRange.end) updateData.endTime = trimmer.timeRange.end

		// console.log("Saved Data",trimmer,currentRotation)
		dispatch(updateMedia(updateData))
	}, [item, trimmer, currentRotation])

	return {
		/** either the data has changed or not */
		hasChanged,
		setHasChanged,
		/** Handler to save the new data */
		handleSave,
	}
}

export { useVideoState }
