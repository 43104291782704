import React, { useCallback, useMemo, createContext, useContext, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMedia } from '../hooks/useMedia'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { findMedia } from '../ui/pages/EditMedia/utils'
import { useTextCardState } from './useTextCardState'
import { useVideoState } from './useVideoState'
import { deleteMedia, duplicateMedia, upscaleImage } from '../../api/app/media'
import useEventListener from '@use-it/event-listener'
import { history } from '../../history'
import { updateEvent } from '../../api/app/events'

/** Build Media Gallery Context */
const MediaItemContext = createContext()

const defaultState = {
	gridLayout: 'grid', // list
}

export const computeMimeType = (mimeType) => {
	if (mimeType) {
		if (/^text/i.test(mimeType)) {
			return 'textcard'
		} else if (/^image/i.test(mimeType)) {
			return 'image'
		} else if (/^video/i.test(mimeType)) {
			return 'video'
		} else if (/^audio/i.test(mimeType)) {
			return 'audio'
		} else return 'unknown'
	} else return undefined
}

// const useModals = () => {
// 	const deleteItem = useDisclosure()

// 	return { deleteItem }
// }

export const useKeyboardControls = ({ prev, next, isEditing, type, item, videoState, textCardState }) => {
	const mediaState = videoState || textCardState
	const hasChanged = (mediaState && mediaState.hasChanged) || false

	const [disableKeyDown, setDisableKeyDown] = useState(isEditing || hasChanged)

	useEffect(() => {
		if (isEditing || hasChanged) {
			setDisableKeyDown(true)
		} else {
			setDisableKeyDown(false)
		}
	}, [hasChanged, isEditing, item])

	const handleKeyDown = (e) => {
		var RIGHT_ARROW_KEY = 'ArrowRight' // next
		var LEFT_ARROW_KEY = 'ArrowLeft' // prev
		if (!disableKeyDown) {
			// Prevent default for hot keys
			if (e.code == RIGHT_ARROW_KEY || e.code == LEFT_ARROW_KEY) {
				e.preventDefault()
			}
			if (e.code == RIGHT_ARROW_KEY) {
				next()
			}
			if (e.code == LEFT_ARROW_KEY) {
				prev()
			}
		}
	}

	useEventListener('keydown', handleKeyDown)
}

export const useTouchControls = ({ prev, next, isEditing }) => {
	/** Swipe and touch gestures */
	const [touchStartX, setTouchStartX] = useState(0)

	const [millisecond, setMillisecond] = useState(0)
	const [timmerActive, setTimmerActive] = useState(false)

	useEffect(() => {
		let interval = null
		if (timmerActive) {
			interval = setInterval(() => {
				setMillisecond((millisecond) => millisecond + 1)
			}, 1)
		} else if (!timmerActive && millisecond !== 0) {
			clearInterval(interval)
		}
		return () => clearInterval(interval)
	}, [timmerActive, millisecond])

	const handleTouchStart = (e) => {
		if (!isEditing) {
			let touchobj = e.changedTouches[0] // reference first touch point (ie: first finger)
			setTouchStartX(parseInt(touchobj.clientX)) // get x position of touch point relative to left edge of browser
			setTimmerActive(true)
		}
	}

	const handleTouchEnd = (event) => {
		if (!isEditing) {
			var s = touchStartX
			var e = event.changedTouches[0].screenX
			var tolerance = s > e ? s - e : e - s
			//alert(millisecond +"|"+ tolerance)
			if (tolerance > 150 && millisecond < 100) {
				if (s < e) {
					prev()
				}
				if (s > e) {
					next()
				}
			}
			setTimmerActive(false)
			setMillisecond(0)
		}
	}

	useEventListener('touchstart', handleTouchStart)
	useEventListener('touchend', handleTouchEnd)

	return false
}
/**
 * The MediaItemProvider provides useful information and handlers relative to the currently display item from a collection of media pertinent to the current event.
 *
 */
const MediaItemProvider = ({ event, children }) => {
	/** Instance of the dispatch method */
	const dispatch = useDispatch()
	/** Deconstruct event object */
	const { id, uuid, mediaSortingOrder, themeTemplateId } = event

	/** Retrieve media ID from params */
	const { mediaId } = useParams()
	/** Retrieve all media of the event */
	const media = useMedia()
	/** Edit the media or update edit mode */
	const [isEditMode, setEditMode] = useState(false)

	const [navigationType, setNavigationType] = useState(null)

	/** Retrieve media item from redux store and memoize result */
	const item = useMemo(() => {
		if (mediaId) {
			return findMedia(media, mediaId)
		}
	}, [media, mediaId])

	/** Retrieve text-card related state (won't be available if do not apply) */
	const textCardState = useTextCardState(item)
	/** Retrieve video related state  (won't be available if do not apply) */
	const videoState = useVideoState(item)
	/** Retrieve the index of the current media through the sorted order */
	const itemIndex = useMemo(() => mediaSortingOrder.indexOf(mediaId), [mediaId])

	/**
	 * Handler to go to the previous media
	 */
	const handlePrevious = (mediaState, unsavedModal) => {
		if (mediaState) {
			const { hasChanged } = mediaState
			if (hasChanged) {
				setNavigationType('previous')
				unsavedModal.onOpen()
			} else {
				setNavigationType(null)
				navigatePrevious()
			}
		} else {
			navigatePrevious()
		}
	}

	/**
	 * Handler to go to the next media
	 */
	const handleNext = (mediaState, unsavedModal) => {
		if (mediaState) {
			const { hasChanged } = mediaState
			if (hasChanged) {
				setNavigationType('next')
				unsavedModal.onOpen()
			} else {
				setNavigationType(null)
				navigateNext()
			}
		} else {
			navigateNext()
		}
	}

	/** Prefetch media item */
	const [prevItem, setPrevItem] = useState()
	const [nextItem, setNextItem] = useState()
	useEffect(() => {
		// Prev Item
		let prev = itemIndex == 0 ? mediaSortingOrder.length - 1 : itemIndex - 1
		let prevMedia = findMedia(media, mediaSortingOrder[prev])
		setPrevItem(prevMedia)
		// Next Item
		let next = itemIndex < mediaSortingOrder.length - 1 ? itemIndex + 1 : 0
		let nextMedia = findMedia(media, mediaSortingOrder[next])
		setNextItem(nextMedia)
	}, [itemIndex])

	/**
	 * navigator to go to the previous media
	 */
	const navigatePrevious = useCallback(() => {
		if (itemIndex !== -1 && mediaSortingOrder) {
			let goto = itemIndex == 0 ? mediaSortingOrder.length - 1 : itemIndex - 1
			history.push(`/event/${uuid}/media/${mediaSortingOrder[goto]}`)
		}
	}, [itemIndex, mediaSortingOrder])

	/**
	 * navigator to go to the next media
	 */
	const navigateNext = useCallback(() => {
		if (itemIndex !== -1 && mediaSortingOrder) {
			let goto = itemIndex < mediaSortingOrder.length - 1 ? itemIndex + 1 : 0
			history.push(`/event/${uuid}/media/${mediaSortingOrder[goto]}`)
		}
	}, [itemIndex, mediaSortingOrder])

	/**
	 * Handler to Remove item - remove from mediaSortingOrder
	 */
	const handleRemove = useCallback(() => {
		const newSortingOrder = mediaSortingOrder.filter((id) => item.id !== id)
		console.log(newSortingOrder, 'newSortingOrder')
		const index = mediaSortingOrder.indexOf(item.id)
		var nextMediaId = ''
		if (index !== -1 && mediaSortingOrder) {
			const goto = index < mediaSortingOrder.length - 1 ? index + 1 : index - 1
			nextMediaId = '#' + mediaSortingOrder[goto]
		}
		history.push(`/event/${uuid}${nextMediaId}`)
		// Update new sorting order
		dispatch(
			updateEvent(
				{
					id: id,
					mediaSortingOrder: newSortingOrder,
				},
				true
			)
		)
	})

	/**
	 * Handler to delete a media.
	 */
	const handleDelete = useCallback(() => {
		const index = mediaSortingOrder.indexOf(item.id)
		var nextMediaId = ''
		if (index !== -1 && mediaSortingOrder) {
			const goto = index < mediaSortingOrder.length - 1 ? index + 1 : index - 1
			nextMediaId = '#' + mediaSortingOrder[goto]
		}
		history.push(`/event/${uuid}${nextMediaId}`)
		dispatch(deleteMedia(item.id, false, true))
	}, [item, uuid])

	/**
	 * Handler to duplicate a media.
	 */
	const handleUpscale = useCallback(() => {
		dispatch(upscaleImage(item.id))
	}, [item])

	/**
	 * Handler to duplicate a media.
	 */
	const handleDuplicate = useCallback(() => {
		dispatch(duplicateMedia({ id: item.id }))
		const hash = item ? '#' + item.id : ''
		history.push(`/event/${uuid}${hash}`)
	}, [item, uuid])

	/**
	 * Handler to navigate back from this media, support for hash
	 */
	const location = useLocation()
	const handleBack = useCallback(() => {
		if (location?.state?.backDestination) {
			history.push(location.state.backDestination)
		} else {
			const hash = item ? '#' + item.id : ''
			history.push(`/event/${uuid}${hash}`)
		}
	}, [item, uuid])

	/**
	 * Determine the file type
	 * (this does not return mime type. refer to
	 * the `computeMimeType` for potential values)
	 */
	const type = useMemo(() => {
		return computeMimeType(item?.mimeType)
	}, [item])

	/**
	 * Build our full state to be returned by this Context Provider
	 */
	const state = {
		/** Send the current Media Item */
		item: item,
		itemIndex: itemIndex,
		/** Type of the media */
		type: type, // One of : 'textcard', 'image', 'video', 'audio', 'unknown'
		/** Send the event template ID */
		themeTemplateId: themeTemplateId,
		/** Send the collection of media belonging to this event */
		media: media,
		/** Send the medium of media upload */
		medium: item ? item.medium : null,
		/** is it a low quality media? */
		hasLowResolution: item ? item.hasLowResolution : false,
		/** Send the contributor of the current media */
		contributor: item ? item.contributor : '',
		/** Are we in editing mode? */
		isEditMode,
		/** Where are we heading */
		navigationType,
		/** Useful navigation handlers tight to the context */
		handlers: {
			navigatePrevious,
			navigateNext,
			setEditMode,
			handleDelete,
			handleRemove,
			handleDuplicate,
			handleBack,
			handlePrevious,
			handleNext,
			handleUpscale,
		},
		/** Specific type-based States and handlers */
		textCardState: type == 'textcard' ? textCardState : undefined,
		videoState: type == 'video' || type == 'audio' ? videoState : undefined,
		prevItem: prevItem,
		nextItem: nextItem,
	}

	return <MediaItemContext.Provider value={state}>{children}</MediaItemContext.Provider>
}
// , areEqual)

// const areEqual = (prevProps, nextProps) => {
// 	if(JSON.stringify(prevProps.event) == JSON.stringify(nextProps.event)) {
// 		return true
// 	}
// 	else return false
// }
export default MediaItemProvider

export const useMediaItemContext = () => useContext(MediaItemContext)
