/**
 * Search through a collection of media and find match for the given media key.
 * @param {*} state from redux
 * @param {*} id of the media
 */
export const findMedia = (collection, id) => {
	let result = null
	if (collection.length > 0) {
		result = collection.find((e) => e.id == id)
	}
	return result
}
