import { useState, useCallback } from 'react'
import { updateMedia } from '../../api/app/media'
import { useDispatch } from 'react-redux'

/**
 * This hook is added to the current Media Item context and concerns text cards only.
 * Essentially, if the media item is a text card, then we want to provide
 * additional features through the context.
 * ----------------------------------------------------------------------------
 * Because this hook is to be used by the MediaItem Context, it needs
 * the `item` passed down and cannot retrieve from context.
 * ----------------------------------------------------------------------------
 * This hook should only be invoked once! So there is no dissociation of state
 * @returns textcard specific state
 */
const useTextCardState = (item) => {
	const dispatch = useDispatch()

	/** Hold value of the text card */
	const [value, setValue] = useState('')
	/** Is the form save button disabled ? */
	const [hasChanged, setHasChanged] = useState(false)
	/** Is the input focused ? */
	const [isFocused, setIsFocused] = useState(false)

	/** Handler for when the value of the text card change */
	const handleValueChange = (media) => {
		/** Update the current value */
		setValue(media.text)
		if (value !== '' && media.text !== item.text) {
			setHasChanged(true)
		}
	}

	/** Handler to set the focus of the element */
	const handleFocus = (value) => {
		setIsFocused(value)
		// if (onFocus) onFocus(value)
	}

	/** Handler for when the value text card is being saved */
	const handleSave = useCallback(() => {
		/** Let's disable the save submit button on save */
		setHasChanged(false)

		/** Create new object and simply pass in the existing id and the new text value (no need to touch any of the other existing props) */
		let postData = {
			id: item.id,
			text: value,
		}
		/** Save existing media */
		dispatch(updateMedia(postData))
	}, [item, value])

	return {
		/**
		 * Boolean to indicate if value saved on the item
		 * and value in the form have changed */
		hasChanged,
		setHasChanged,
		/** Is the input focused ? */
		isFocused,
		/** Value of the text card  */
		value,
		/** Handler to set a new value for the textcard */
		handleValueChange,
		/** Handler to set a new value for the textcard */
		handleFocus,
		/** Handler to save the new value */
		handleSave,
	}
}

export { useTextCardState }
