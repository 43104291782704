import React from 'react'
import { Button } from '@chakra-ui/react'
import { CancelRounded } from '../Icon'

const CancelButton = ({ onClick }) => {
	return (
		<Button variant="control" onClick={onClick}>
			<CancelRounded />
			Cancel
		</Button>
	)
}

export default CancelButton
